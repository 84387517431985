<template>
  <div class="product-slider-wrapper">
    <div class="slider-thumbs-wrapper">
      <div v-if="selectedVariant?.images.length > 0" class="slider-thumbs-container">
        <div
          v-for="(image, idx) in selectedVariant?.images"
          :key="`tmb-${idx}`"
          @click="setActiveProducts(image)"
          :class="[
            'slider-thumb',
            activeProducts === image
              ? 'border-secondary'
              : 'border-active',
          ]"
        >
          <NuxtImg
            :src="getImage(image?.medium)"
            width="120"
            height="80"
            fit="cover"
            loading="lazy"
            format="webp"
            :placeholder="[60, 40, 93, 3]"
            :quality="98"
            class="slider-thumb-img"
          />
        </div>
      </div>
    </div>

    <div class="slider-content-wrapper">
      <div class="slider-content-container">
        <NuxtImg
            :src="getImage(activeProducts?.medium)"
            width="1200"
            height="800"
            fit="outside"
            loading="lazy"
            format="webp"
            :placeholder="[50, 50, 93, 3]"
            :quality="98"
            :alt="selectedVariant?.title"
            @click="open(activeProducts)"
            class="slider-content-img"
        />
        <div v-if="selectedVariant?.isFreeShipping" class="flex absolute z-0 justify-center text-center items-end top-0 left-0">
          <span class="py-2 px-4 text-sm bg-lightgray-50 w-full rounded-br-lg flex items-center justify-center">
            <FastIcon class="h-5 w-5 mr-2 fill-primary" />
            <span>{{ $t('app.free-shipping') }}</span>
          </span>
        </div>
      </div>
    </div>

    <ClientOnly>
      <ProductModal />
    </ClientOnly>

    <div class="md:hidden w-full">
      <SwiperImages />
    </div>
  </div>
</template>

<script setup>
import "assets/css/modules/product-slider-4.css"
import FastIcon from "~/assets/icons/fast.svg";

const openProductModal = useState("openProductModal", () => false);
const { selectedVariant, product } = await useProduct();
const {
  selectedImage
} = useZoom()

const activeProducts = useState(() => selectedVariant.value?.images[0]);
const setActiveProducts = (image) => {
  activeProducts.value = image;
};

const open = (image) => {
  openProductModal.value = true
  selectedImage.value = image?.original;
}

watchEffect(() => {
  setActiveProducts(selectedVariant.value?.images[0]);
});

</script>
